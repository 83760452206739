import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

const Video = styled.video<ThemedProps>`
    position: fixed;
    z-index: -1;

    @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
    }

    @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%;
    }

    ${(props: ThemedProps) => props.theme.mediaQueries.mobile} {
        display: none;
    }
`;

interface Props {
    loop?: boolean;
    posterURI: string;
    videoURI: string;
    videoType: string;
    children: ReactNode;
}

const BackgroundVideo: FunctionComponent<Props> = ({ loop = false, posterURI, videoURI, videoType, children }) => (
    <>
        <Video poster={posterURI} autoPlay muted loop={loop}>
            <source src={videoURI} type={videoType} />
        </Video>
        {children}
    </>
);
export default BackgroundVideo;
