import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

interface ButtonProps extends ThemedProps {
    variant?: 'primary' | 'secondary';
}

const Button = styled.button<ButtonProps>`
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background: ${({ variant = 'primary', theme }: ButtonProps) =>
        variant === 'secondary' ? theme.colors.white : theme.colors.yellow};
    color: ${({ theme }: ButtonProps) => theme.colors.black};
    padding: 0.75em 2em;
    border-radius: ${({ theme }: ButtonProps) => theme.borders.radius};
    border: none;
    outline: none;
    text-align: center;
    box-shadow: ${({ theme }: ButtonProps) => theme.effects.shadow};
    font-weight: ${({ theme }: ButtonProps) => theme.typography.weights.bold};
    transition: all 0.3s ease-in-out;
`;

export default Button;
