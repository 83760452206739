import React, { FunctionComponent, useState, MouseEvent, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

interface Props {
    duration?: number;
    variant?: 'primary' | 'secondary';
}

type RippleContainerProps = Props & ThemedProps;

const RippleContainer = styled.div<RippleContainerProps>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    span {
        transform: scale(0);
        border-radius: 100%;
        position: absolute;
        opacity: 0.5;
        background-color: ${({ variant = 'primary', theme }: RippleContainerProps) =>
            variant === 'secondary' ? theme.colors.yellow : theme.colors.white};
        animation-name: ripple;
        animation-duration: ${(props: RippleContainerProps) => props.duration}ms;
    }

    @keyframes ripple {
        to {
            opacity: 0;
            transform: scale(2);
        }
    }
`;

interface RippleConfig {
    x: number;
    y: number;
    size: number;
}

const useDebouncedRippleCleanUp = (rippleCount: number, duration: number, cleanUpFunction: () => void) => {
    useLayoutEffect(() => {
        let bounce: any = null;
        if (rippleCount > 0) {
            clearTimeout(bounce);

            bounce = setTimeout(() => {
                cleanUpFunction();
                bounce && clearTimeout(bounce);
            }, duration * 4);
        }

        return () => clearTimeout(bounce);
    }, [rippleCount, duration, cleanUpFunction]);
};

const Ripple: FunctionComponent<Props> = ({ duration = 850, variant }) => {
    const [ripples, setRipples] = useState<RippleConfig[]>([]);

    useDebouncedRippleCleanUp(ripples.length, duration, () => {
        setRipples([]);
    });

    const addRipple = (event: MouseEvent<HTMLDivElement>) => {
        const rippleContainer = event.currentTarget.getBoundingClientRect();

        const size = rippleContainer.width > rippleContainer.height ? rippleContainer.width : rippleContainer.height;
        const x = event.pageX - rippleContainer.x - size / 2;
        const y = event.pageY - rippleContainer.y - size / 2;

        setRipples((prevState) => [...prevState, { x, y, size }]);
    };

    return (
        <RippleContainer onMouseDown={addRipple} duration={duration} variant={variant}>
            {ripples.length > 0 &&
                ripples.map((ripple, index) => (
                    <span
                        key={`ripple_${index}`}
                        style={{
                            top: ripple.y,
                            left: ripple.x,
                            width: ripple.size,
                            height: ripple.size,
                        }}
                    />
                ))}
        </RippleContainer>
    );
};

export default Ripple;
