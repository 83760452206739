import React, { FunctionComponent } from 'react';
import BackgroundVideo from '../components/backround-video';
import SmokeVideoFirstFrameUrl from '../static/images/smoke-video-first-frame.png';
import SmokeVideoLastFrameUrl from '../static/images/smoke-video-last-frame.png';
import SmokeVideo from '../videos/smoke.webm';
import styled from 'styled-components';
import Button from '../components/button';
import Ripple from '../components/ripple';
import {
    STORAGE_ITEM_KEY,
    STORAGE_ITEM_VALUE,
    ProvideProtectedViewContext,
} from '../context-providers/protection-provider';
import { ThemedProps } from '../styles/theme';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const LogoImage = styled(Img)<ThemedProps>`
    width: auto;
    height: auto;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        width: 100%;
        margin: 1rem;
    }
`;

const AuthPageSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    padding: 1rem;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        background-image: url(${SmokeVideoLastFrameUrl});
    }
`;

const InfoText = styled.p<ThemedProps>`
    text-align: center;
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.large};
    font-weight: ${(props: ThemedProps) => props.theme.typography.weights.normal};
    color: ${(props: ThemedProps) => props.theme.colors.white};
    margin: 0.5rem;
`;

const QuestionText = styled.p<ThemedProps>`
    text-align: center;
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.normal};
    color: ${(props: ThemedProps) => props.theme.colors.yellow};
    font-weight: ${(props: ThemedProps) => props.theme.typography.weights.bold};
    margin: 0.5rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${Button} {
        margin: 0.5rem;
    }
`;

const AuthPage: FunctionComponent = () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "main-logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1080) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const { setAuth } = { ...ProvideProtectedViewContext() };

    const handlePositiveUserAuth = () => {
        localStorage.setItem(STORAGE_ITEM_KEY, STORAGE_ITEM_VALUE);
        setAuth && setAuth(true);
    };

    const handleNegativeUserAuth = () => {
        window.location.href = process.env.GATSBY_KING_VAPE_FACEBOOK_URL || '';
    };

    return (
        <BackgroundVideo loop posterURI={SmokeVideoFirstFrameUrl} videoURI={SmokeVideo} videoType="video/webm">
            <AuthPageSection>
                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-ignore*/}
                <LogoImage fluid={data.logo.childImageSharp.fluid} alt="King Vape" />
                <InfoText>E-PAPIEROSY | SNUSY | SHISHA | CBD | BONGO | WAPORYZATORY - największy punkt w Twoim mieście. Wszystko w jednym miejscu!"</InfoText>
                <QuestionText>Czy masz ukończone 18 lat?</QuestionText>
                <ButtonsContainer>
                    <Button onClick={() => handlePositiveUserAuth()}>
                        Tak
                        <Ripple />
                    </Button>
                    <Button variant="secondary" onClick={() => handleNegativeUserAuth()}>
                        Nie
                        <Ripple variant="secondary" />
                    </Button>
                </ButtonsContainer>
            </AuthPageSection>
        </BackgroundVideo>
    );
};

export default AuthPage;
